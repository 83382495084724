<template>
  <div
    :class="[
      experienceBurningOrItchingSensationOnSkin == null ? 'bg-red-500' : '',
    ]"
    class="p-2"
  >
    <TwLabel>{{
      $t("experience_burning_or_itching_sensation_on_skin")
    }}</TwLabel>
    <TwButtonsYesAndNo
      :isActive="experienceBurningOrItchingSensationOnSkin"
      @onClickYes="updateExperienceBurningOrItchingSensationOnSkin(true)"
      @onClickNo="updateExperienceBurningOrItchingSensationOnSkin(false)"
    />
  </div>
</template>

<script setup lang="ts">
import { ComputedRef, computed } from "vue";
import { useStore } from "vuex";
import TwLabel from "../TwLabel.vue";
import TwButtonsYesAndNo from "../TwButtonsYesAndNo.vue";

const store = useStore();

const experienceBurningOrItchingSensationOnSkin: ComputedRef<boolean> =
  computed(
    () => store.state.form.experience_burning_or_itching_sensation_on_skin
  );
const updateExperienceBurningOrItchingSensationOnSkin = (value: boolean) => {
  store.dispatch("updateExperienceBurningOrItchingSensationOnSkin", value);
};
</script>

<style scoped></style>
