<!-- HadFacial.vue -->
<template>
  <div
    :class="[haveReceivedBotoxAndOthers == null ? 'bg-red-500' : '']"
    class="p-2"
  >
    <TwLabel>{{ $t("have_you_received_botox_and_others") }}</TwLabel>
    <TwButtonsYesAndNo
      :isActive="haveReceivedBotoxAndOthers"
      @onClickYes="updateHaveReceivedBotoxAndOthers(true)"
      @onClickNo="updateHaveReceivedBotoxAndOthers(false)"
    />
    <div v-if="haveReceivedBotoxAndOthers" class="ml-4 mr-4">
      <TwLabel>{{ $t("details") }}</TwLabel>
      <TwTextarea
        v-model="haveReceivedBotoxAndOthersDetails"
        :rows="2"
        id="haveReceivedBotoxAndOthersDetails"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ComputedRef, WritableComputedRef, computed } from "vue";
import { useStore } from "vuex";
import TwLabel from "../TwLabel.vue";
import TwTextarea from "../TwTextarea.vue";
import TwButtonsYesAndNo from "../TwButtonsYesAndNo.vue";

const store = useStore();

const haveReceivedBotoxAndOthers: ComputedRef<boolean> = computed(
  () => store.state.form.have_received_botox_and_others
);
const haveReceivedBotoxAndOthersDetails: WritableComputedRef<string> = computed(
  {
    get: () => store.state.form.have_received_botox_and_others_details,
    set: (value) =>
      store.dispatch("updateHaveReceivedBotoxAndOthersDetails", value),
  }
);

const updateHaveReceivedBotoxAndOthers = (value: boolean) => {
  store.dispatch("updateHaveReceivedBotoxAndOthers", value);
};
</script>

<style scoped></style>
