<template>
  <TwLabel>{{ $t("areas_of_concern_in_skin") }}</TwLabel>
  <TwCheckbox
    :options="[
      {
        label: $t('areas_of_concern_in_skin_breakouts_acne'),
        value: 'areas_of_concern_in_skin_breakouts_acne',
      },
      {
        label: $t('areas_of_concern_in_skin_blackheads_whiteheads'),
        value: 'areas_of_concern_in_skin_blackheads_whiteheads',
      },
      {
        label: $t('areas_of_concern_in_skin_uneven_skin_tone'),
        value: 'areas_of_concern_in_skin_uneven_skin_tone',
      },
      {
        label: $t('areas_of_concern_in_skin_sun_damage'),
        value: 'areas_of_concern_in_skin_sun_damage',
      },
      {
        label: $t('areas_of_concern_in_skin_excessive_oil_shine'),
        value: 'areas_of_concern_in_skin_excessive_oil_shine',
      },
      {
        label: $t('areas_of_concern_in_skin_wrinkles_fine_lines'),
        value: 'areas_of_concern_in_skin_wrinkles_fine_lines',
      },
      {
        label: $t('areas_of_concern_in_skin_dull_dry_skin'),
        value: 'areas_of_concern_in_skin_dull_dry_skin',
      },
      {
        label: $t('areas_of_concern_in_skin_rosacea'),
        value: 'areas_of_concern_in_skin_rosacea',
      },
      {
        label: $t('areas_of_concern_in_skin_broken_capillaries'),
        value: 'areas_of_concern_in_skin_broken_capillaries',
      },
      {
        label: $t('areas_of_concern_in_skin_redness_ruddiness'),
        value: 'areas_of_concern_in_skin_redness_ruddiness',
      },
      {
        label: $t('areas_of_concern_in_skin_dehydration'),
        value: 'areas_of_concern_in_skin_dehydration',
      },
      {
        label: $t('areas_of_concern_in_skin_spots'),
        value: 'areas_of_concern_in_skin_spots',
      },
    ]"
    v-model="selectedConcerns"
    @update:modelValue="updateConcerns"
  />
  <div class="ml-4 mr-4">
    <TwLabel>{{ $t("areas_of_concern_in_skin_other") }}</TwLabel>
    <TwTextarea
      v-model="areasOfConcernInSkinOther"
      :rows="1"
      id="areasOfConcernInSkinOther"
    />
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useStore } from "vuex";
import TwLabel from "../TwLabel.vue";
import TwCheckbox from "../TwCheckbox.vue";
import TwTextarea from "../TwTextarea.vue";

const store = useStore();

const areasOfConcernInSkinOther = computed({
  get: () => store.state.form.areas_of_concern_in_skin_other,
  set: (value) => store.dispatch("updateAreasOfConcernInSkinOther", value),
});

const selectedConcerns = computed(() => {
  return Object.keys(store.state.form).filter((key) => store.state.form[key]);
});

const updateConcerns = (newValues: string[]) => {
  newValues.forEach((value) => {
    switch (value) {
      case "areas_of_concern_in_skin_breakouts_acne":
        store.dispatch("updateAreasOfConcernInSkinBreakoutsAcne", true);
        break;
      case "areas_of_concern_in_skin_blackheads_whiteheads":
        store.dispatch("updateAreasOfConcernInSkinBlackheadsWhiteheads", true);
        break;
      case "areas_of_concern_in_skin_uneven_skin_tone":
        store.dispatch("updateAreasOfConcernInSkinUnevenSkinTone", true);
        break;
      case "areas_of_concern_in_skin_sun_damage":
        store.dispatch("updateAreasOfConcernInSkinSunDamage", true);
        break;
      case "areas_of_concern_in_skin_excessive_oil_shine":
        store.dispatch("updateAreasOfConcernInSkinExcessiveOilShine", true);
        break;
      case "areas_of_concern_in_skin_wrinkles_fine_lines":
        store.dispatch("updateAreasOfConcernInSkinWrinklesFineLines", true);
        break;
      case "areas_of_concern_in_skin_dull_dry_skin":
        store.dispatch("updateAreasOfConcernInSkinDullDrySkin", true);
        break;
      case "areas_of_concern_in_skin_rosacea":
        store.dispatch("updateAreasOfConcernInSkinRosacea", true);
        break;
      case "areas_of_concern_in_skin_broken_capillaries":
        store.dispatch("updateAreasOfConcernInSkinBrokenCapillaries", true);
        break;
      case "areas_of_concern_in_skin_redness_ruddiness":
        store.dispatch("updateAreasOfConcernInSkinRednessRuddiness", true);
        break;
      case "areas_of_concern_in_skin_dehydration":
        store.dispatch("updateAreasOfConcernInSkinDehydration", true);
        break;
      case "areas_of_concern_in_skin_spots":
        store.dispatch("updateAreasOfConcernInSkinSpots", true);
        break;
    }
  });
};
</script>
