<template>
  <div :class="[hadFacialBefore == null ? 'bg-red-500' : '']" class="p-2">
    <TwLabel>{{ $t("had_facial_before") }}</TwLabel>
    <TwButtonsYesAndNo
      :isActive="hadFacialBefore"
      @onClickYes="updateHadFacialBefore(true)"
      @onClickNo="updateHadFacialBefore(false)"
    />
    <div v-if="hadFacialBefore" class="ml-4 mr-4">
      <TwLabel>{{ $t("when") }}</TwLabel>
      <TwTextarea
        v-model="hadFacialBeforeWhen"
        :rows="1"
        id="hadFacialBeforeWhen"
      />
      <TwLabel>{{ $t("how_often") }}</TwLabel>
      <TwTextarea
        v-model="hadFacialBeforeHowOften"
        :rows="1"
        id="hadFacialBeforeHowOften"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useStore } from "vuex";
import TwLabel from "../TwLabel.vue";
import TwTextarea from "../TwTextarea.vue";
import TwButtonsYesAndNo from "../TwButtonsYesAndNo.vue";

const store = useStore();

const hadFacialBefore = computed(() => store.state.form.had_facial_before);
const hadFacialBeforeWhen = computed({
  get: () => store.state.form.had_facial_before_when,
  set: (value) => store.dispatch("updateHadFacialBeforeWhen", value),
});
const hadFacialBeforeHowOften = computed({
  get: () => store.state.form.had_facial_before_how_often,
  set: (value) => store.dispatch("updateHadFacialBeforeHowOften", value),
});

const updateHadFacialBefore = (value: boolean) => {
  store.dispatch("updateHadFacialBefore", value);
};
</script>

<style scoped></style>
