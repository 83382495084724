<template>
  <div
    class="flex items-center justify-center min-h-screen bg-white dark:bg-black"
  >
    <button
      @click="goToDashboard"
      class="px-4 py-2 text-white bg-black border rounded dark:bg-black dark:text-white"
    >
      Ir al Dashboard
    </button>
  </div>
</template>

<script>
export default {
  name: "DashboardButton",
  methods: {
    goToDashboard() {
      window.open("/dashboard", "_blank");
    },
  },
};
</script>

<style scoped>
/* Aquí puedes agregar estilos adicionales si es necesario */
</style>
