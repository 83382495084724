<template>
  <div
    :class="[areYouTakingMedicationsAndOthers == null ? 'bg-red-500' : '']"
    class="p-2"
  >
    <TwLabel>{{ $t("are_you_taking_medications_and_others") }}</TwLabel>
    <TwButtonsYesAndNo
      :isActive="areYouTakingMedicationsAndOthers"
      @onClickYes="updateAreYouTakingMedicationsAndOthers(true)"
      @onClickNo="updateAreYouTakingMedicationsAndOthers(false)"
    />
    <div v-if="areYouTakingMedicationsAndOthers" class="ml-4 mr-4">
      <TwLabel>{{ $t("oral") }}</TwLabel>
      <TwTextarea
        v-model="areYouTakingMedicationsAndOthersOral"
        :rows="2"
        id="areYouTakingMedicationsAndOthersOral"
      />
      <TwLabel>{{ $t("topical") }}</TwLabel>
      <TwTextarea
        v-model="areYouTakingMedicationsAndOthersTopical"
        :rows="2"
        id="areYouTakingMedicationsAndOthersTopical"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ComputedRef, computed, WritableComputedRef } from "vue";
import { useStore } from "vuex";
import TwLabel from "../TwLabel.vue";
import TwTextarea from "../TwTextarea.vue";
import TwButtonsYesAndNo from "../TwButtonsYesAndNo.vue";

const store = useStore();

const areYouTakingMedicationsAndOthers: ComputedRef<boolean> = computed(
  () => store.state.form.are_you_taking_medications_and_others
);
const areYouTakingMedicationsAndOthersOral: WritableComputedRef<string> =
  computed({
    get: () => store.state.form.are_you_taking_medications_and_others_oral,
    set: (value) =>
      store.dispatch("updateAreYouTakingMedicationsAndOthersOral", value),
  });
const areYouTakingMedicationsAndOthersTopical: WritableComputedRef<string> =
  computed({
    get: () => store.state.form.are_you_taking_medications_and_others_topical,
    set: (value) =>
      store.dispatch("updateAreYouTakingMedicationsAndOthersTopical", value),
  });

const updateAreYouTakingMedicationsAndOthers = (value: boolean) => {
  store.dispatch("updateAreYouTakingMedicationsAndOthers", value);
};
</script>

<style scoped></style>
