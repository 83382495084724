import { createI18n } from "vue-i18n";
import strings_english from "./strings_english.json";
import strings_spanish from "./strings_spanish.json";

const i18n = createI18n({
  legacy: false,
  locale: "en",
  fallbackLocale: "en",
  messages: {
    en: strings_english,
    es: strings_spanish,
  },
});

export default i18n;
