import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import FormView from "../views/FormView.vue";

import i18n from "@/i18n/i18n";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "homeEn",
    component: FormView,
    meta: { locale: "en" },
  },
  {
    path: "/es",
    name: "homeEs",
    component: FormView,
    meta: { locale: "es" },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, _from, next) => {
  const locale = to.meta.locale;
  if (locale) {
    i18n.global.locale.value = locale as "es" | "en";
  }
  next();
});

export default router;
