<template>
  <div class="flex space-x-4 mt-2 mb-4">
    <button
      :class="getClassesYes()"
      class="px-4 py-2 rounded"
      @click="onClickYes()"
    >
      {{ $t("yes") }}
    </button>

    <button
      :class="getClassesNo()"
      class="px-4 py-2 rounded"
      @click="onClickNo()"
    >
      {{ $t("no") }}
    </button>
  </div>
</template>

<script setup lang="ts">
import { defineProps, defineEmits } from "vue";

const props = defineProps<{ isActive: boolean | null }>();
const emit = defineEmits(["onClickYes", "onClickNo"]);
const notSelectedButtonClasses = "bg-gray-200 text-gray-700";
const selectedButtonClasses = "bg-blue-500 text-white";

function onClickYes() {
  emit("onClickYes");
}

function onClickNo() {
  emit("onClickNo");
}

function getClassesNo(): Array<string> {
  let classes: Array<string> = [];
  if (props.isActive == null || props.isActive === true) {
    classes = [notSelectedButtonClasses];
  } else if (props.isActive === false) {
    classes = [selectedButtonClasses];
  }
  return classes;
}

function getClassesYes(): Array<string> {
  let classes: Array<string> = [];
  if (props.isActive == null || props.isActive === false) {
    classes = [notSelectedButtonClasses];
  } else if (props.isActive === true) {
    classes = [selectedButtonClasses];
  }
  return classes;
}
</script>
