<!-- FormView.vue -->
<template>
  <!-- {{ form }} -->
  <div class="h-full bg-slate-50">
    <div class="h-full flex p-3 bg-slate-50 items-center justify-center">
      <div class="max-w-5xl h-[97%] sm:flexxx rounded-lg">
        <div class="p-3">
          <!-- Banderas de lenguaje -->
          <div style="display: flex" class="">
            <router-link :to="'/'" class="mr-2">
              <img
                src="https://assets.brian.com.co/web/flags/usa.png"
                alt=""
                width="24"
              />
            </router-link>
            <router-link :to="'/es'">
              <img
                src="https://assets.brian.com.co/web/flags/spain.png"
                alt=""
                width="24"
              />
            </router-link>
            <button @click="store.dispatch('resetFormState')" class="ml-3 mr-3">
              Limpiar
            </button>
          </div>
        </div>
        <HadFacial />
        <TwDivider />
        <HaveReceivedBotoxAndOthers />
        <TwDivider />
        <CurrentlyUseAccutaneAndOthers />
        <TwDivider />
        <TakingMedicationsAndOthers />
        <TwDivider />
        <HaveTanningLast48Hours />
        <TwDivider />
        <SkinType />
        <TwDivider />
        <SkinTypeDescription />
        <TwDivider />
        <AreasOfConcernInSkin />
        <TwDivider />
        <ExperienceBurningItchingSensation />
        <TwDivider />
        <ExperienceSkinBreakouts />
        <TwDivider />
        <ExperienceOilyShine />
        <TwDivider />
        <!-- <div class="">
          <vue-json-pretty :data="form"></vue-json-pretty>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import VueJsonPretty from "vue-json-pretty";
import "vue-json-pretty/lib/styles.css";

import TwDivider from "@/components/TwDivider.vue";
import HadFacial from "@/components/FormComponents/1.HadFacial.vue";
import HaveReceivedBotoxAndOthers from "@/components/FormComponents/2.HaveReceivedBotoxAndOthers.vue";
import CurrentlyUseAccutaneAndOthers from "@/components/FormComponents/3.CurrentlyUseAccutaneAndOthers.vue";
import TakingMedicationsAndOthers from "@/components/FormComponents/4.TakingMedicationsAndOthers.vue";
import HaveTanningLast48Hours from "@/components/FormComponents/5.HaveTanningLast48Hours.vue";
import SkinType from "@/components/FormComponents/6.SkinType.vue";
import SkinTypeDescription from "@/components/FormComponents/7.SkinTypeDescription.vue";
import AreasOfConcernInSkin from "@/components/FormComponents/8.AreasOfConcernInSkin.vue";
import ExperienceBurningItchingSensation from "@/components/FormComponents/9.ExperienceBurningItchingSensation.vue";
import ExperienceSkinBreakouts from "@/components/FormComponents/10.ExperienceSkinBreakouts.vue";
import ExperienceOilyShine from "@/components/FormComponents/11.ExperienceOilyShine.vue";

import { computed } from "vue";
import { useStore } from "vuex";

const store = useStore();

const form = computed(() => store.state.form);
</script>

<style scoped>
/* Custom styles si es necesario */
</style>
