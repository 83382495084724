// store/index.ts
import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";
import { Form } from "@/form_interface";

const initialState: Form = {
  had_facial_before: null,
  had_facial_before_when: null,
  had_facial_before_how_often: null,
  ///
  have_received_botox_and_others: null,
  have_received_botox_and_others_details: null,
  ///
  currently_use_accutane_and_others: null,
  currently_use_accutane_and_others_which_ones: null,
  currently_use_accutane_and_others_how_long: null,
  ///
  currently_use_products_with_glycolic_acid_and_others: null,
  currently_use_products_with_glycolic_acid_and_others_which_ones: null,
  ///
  are_you_taking_medications_and_others: null,
  are_you_taking_medications_and_others_oral: null,
  are_you_taking_medications_and_others_topical: null,
  ///
  have_you_tanning_last_48_hours: null,
  ///
  skin_type: null,
  ///
  skin_type_description: null,
  ///
  areas_of_concern_in_skin_breakouts_acne: false,
  areas_of_concern_in_skin_blackheads_whiteheads: false,
  areas_of_concern_in_skin_uneven_skin_tone: false,
  areas_of_concern_in_skin_sun_damage: false,
  areas_of_concern_in_skin_excessive_oil_shine: false,
  areas_of_concern_in_skin_wrinkles_fine_lines: false,
  areas_of_concern_in_skin_dull_dry_skin: false,
  areas_of_concern_in_skin_rosacea: false,
  areas_of_concern_in_skin_broken_capillaries: false,
  areas_of_concern_in_skin_redness_ruddiness: false,
  areas_of_concern_in_skin_dehydration: false,
  areas_of_concern_in_skin_spots: false,
  areas_of_concern_in_skin_other: null,
  ///
  experience_burning_or_itching_sensation_on_skin: null,
  ///
  experience_skin_breakouts: null,
  ///
  experience_oily_shine: null,
};

export default createStore({
  state: {
    form: { ...initialState }, // Inicializa el estado con el estado inicial
  },
  mutations: {
    /// experience_oily_shine
    SET_EXPERIENCE_OILY_SHINE(state, value: boolean) {
      state.form.experience_oily_shine = value;
    },
    /// experience_oily_shine

    /// experience_skin_breakouts
    SET_EXPERIENCE_SKIN_BREAKOUTS(state, value: boolean) {
      state.form.experience_skin_breakouts = value;
    },
    /// experience_skin_breakouts

    /// experience_burning_or_itching_sensation_on_skin
    SET_EXPERIENCE_BURNING_OR_ITCHING_SENSATION_ON_SKIN(state, value: boolean) {
      state.form.experience_burning_or_itching_sensation_on_skin = value;
    },
    /// experience_burning_or_itching_sensation_on_skin

    /// areas_of_concern_in_skin
    SET_AREAS_OF_CONCERN_IN_SKIN_BREAKOUTS_ACNE(state, value: boolean) {
      state.form.areas_of_concern_in_skin_breakouts_acne = value;
    },
    SET_AREAS_OF_CONCERN_IN_SKIN_BLACKHEADS_WHITEHEADS(state, value: boolean) {
      state.form.areas_of_concern_in_skin_blackheads_whiteheads = value;
    },
    SET_AREAS_OF_CONCERN_IN_SKIN_UNEVEN_SKIN_TONE(state, value: boolean) {
      state.form.areas_of_concern_in_skin_uneven_skin_tone = value;
    },
    SET_AREAS_OF_CONCERN_IN_SKIN_SUN_DAMAGE(state, value: boolean) {
      state.form.areas_of_concern_in_skin_sun_damage = value;
    },
    SET_AREAS_OF_CONCERN_IN_SKIN_EXCESSIVE_OIL_SHINE(state, value: boolean) {
      state.form.areas_of_concern_in_skin_excessive_oil_shine = value;
    },
    SET_AREAS_OF_CONCERN_IN_SKIN_WRINKLES_FINE_LINES(state, value: boolean) {
      state.form.areas_of_concern_in_skin_wrinkles_fine_lines = value;
    },
    SET_AREAS_OF_CONCERN_IN_SKIN_DULL_DRY_SKIN(state, value: boolean) {
      state.form.areas_of_concern_in_skin_dull_dry_skin = value;
    },
    SET_AREAS_OF_CONCERN_IN_SKIN_ROSACEA(state, value: boolean) {
      state.form.areas_of_concern_in_skin_rosacea = value;
    },
    SET_AREAS_OF_CONCERN_IN_SKIN_BROKEN_CAPILLARIES(state, value: boolean) {
      state.form.areas_of_concern_in_skin_broken_capillaries = value;
    },
    SET_AREAS_OF_CONCERN_IN_SKIN_REDNESS_RUDDINESS(state, value: boolean) {
      state.form.areas_of_concern_in_skin_redness_ruddiness = value;
    },
    SET_AREAS_OF_CONCERN_IN_SKIN_DEHYDRATION(state, value: boolean) {
      state.form.areas_of_concern_in_skin_dehydration = value;
    },
    SET_AREAS_OF_CONCERN_IN_SKIN_SPOTS(state, value: boolean) {
      state.form.areas_of_concern_in_skin_spots = value;
    },
    SET_AREAS_OF_CONCERN_IN_SKIN_OTHER(state, value: string) {
      state.form.areas_of_concern_in_skin_other = value;
    },
    /// areas_of_concern_in_skin

    /// skin_type_description
    SET_SKIN_TYPE_DESCRIPTION(state, value: string) {
      state.form.skin_type_description = value;
    },
    /// skin_type_description

    /// skin_type
    SET_SKIN_TYPE(state, value: string) {
      state.form.skin_type = value;
    },
    /// skin_type

    /// have_you_tanning_last_48_hours
    SET_HAVE_YOU_TANNING_LAST_48_HOURS(state, value: boolean) {
      state.form.have_you_tanning_last_48_hours = value;
    },
    /// have_you_tanning_last_48_hours

    /// are_you_taking_medications_and_othersc
    SET_ARE_YOU_TAKING_MEDICATIONS_AND_OTHERS(state, value: boolean) {
      state.form.are_you_taking_medications_and_others = value;
    },
    SET_ARE_YOU_TAKING_MEDICATIONS_AND_OTHERS_ORAL(state, value: string) {
      state.form.are_you_taking_medications_and_others_oral = value;
    },
    SET_ARE_YOU_TAKING_MEDICATIONS_AND_OTHERS_TOPICAL(state, value: string) {
      state.form.are_you_taking_medications_and_others_topical = value;
    },
    /// are_you_taking_medications_and_others

    /// currently_use_products_with_glycolic_acid_and_others
    SET_CURRENTLY_USE_PRODUCTS_WITH_GLYCOLIC_ACID_AND_OTHERS(
      state,
      value: boolean
    ) {
      state.form.currently_use_products_with_glycolic_acid_and_others = value;
    },
    SET_CURRENTLY_USE_PRODUCTS_WITH_GLYCOLIC_ACID_AND_OTHERS_WHICH_ONES(
      state,
      value: string
    ) {
      state.form.currently_use_products_with_glycolic_acid_and_others_which_ones =
        value;
    },
    /// currently_use_products_with_glycolic_acid_and_others

    /// currently_use_accutane_and_others
    SET_CURRENTLY_USE_ACCUTANE_AND_OTHERS(state, value: boolean) {
      state.form.currently_use_accutane_and_others = value;
    },
    SET_CURRENTLY_USE_ACCUTANE_AND_OTHERS_WHICH_ONES(state, value: string) {
      state.form.currently_use_accutane_and_others_which_ones = value;
    },
    SET_CURRENTLY_USE_ACCUTANE_AND_OTHERS_HOW_LONG(state, value: string) {
      state.form.currently_use_accutane_and_others_how_long = value;
    },
    /// currently_use_accutane_and_others

    /// have_received_botox_and_others
    SET_HAVE_RECEIVED_BOTOX_AND_OTHERS(state, value: boolean) {
      state.form.have_received_botox_and_others = value;
    },
    SET_HAVE_RECEIVED_BOTOX_AND_OTHERS_DETAILS(state, value: string) {
      state.form.have_received_botox_and_others_details = value;
    },
    /// have_received_botox_and_others

    /// had_facial_before
    SET_HAD_FACIAL_BEFORE(state, value: boolean) {
      state.form.had_facial_before = value;
    },
    SET_HAD_FACIAL_BEFORE_WHEN(state, value: string) {
      state.form.had_facial_before_when = value;
    },
    SET_HAD_FACIAL_BEFORE_HOW_OFTEN(state, value: string) {
      state.form.had_facial_before_how_often = value;
    },
    /// had_facial_before
    resetFormState(state) {
      state.form = { ...initialState };
    },
  },
  actions: {
    /// experience_oily_shine
    updateExperienceOilyShine({ commit }, value: boolean) {
      commit("SET_EXPERIENCE_OILY_SHINE", value);
    },
    /// experience_oily_shine

    /// experience_skin_breakouts
    updateExperienceSkinBreakouts({ commit }, value: boolean) {
      commit("SET_EXPERIENCE_SKIN_BREAKOUTS", value);
    },
    /// experience_skin_breakouts

    /// experience_burning_or_itching_sensation_on_skin
    updateExperienceBurningOrItchingSensationOnSkin(
      { commit },
      value: boolean
    ) {
      commit("SET_EXPERIENCE_BURNING_OR_ITCHING_SENSATION_ON_SKIN", value);
    },
    /// experience_burning_or_itching_sensation_on_skin

    /// areas_of_concern_in_skin
    updateAreasOfConcernInSkinBreakoutsAcne({ commit }, value: boolean) {
      commit("SET_AREAS_OF_CONCERN_IN_SKIN_BREAKOUTS_ACNE", value);
    },

    updateAreasOfConcernInSkinBlackheadsWhiteheads({ commit }, value: boolean) {
      commit("SET_AREAS_OF_CONCERN_IN_SKIN_BLACKHEADS_WHITEHEADS", value);
    },

    updateAreasOfConcernInSkinUnevenSkinTone({ commit }, value: boolean) {
      commit("SET_AREAS_OF_CONCERN_IN_SKIN_UNEVEN_SKIN_TONE", value);
    },

    updateAreasOfConcernInSkinSunDamage({ commit }, value: boolean) {
      commit("SET_AREAS_OF_CONCERN_IN_SKIN_SUN_DAMAGE", value);
    },

    updateAreasOfConcernInSkinExcessiveOilShine({ commit }, value: boolean) {
      commit("SET_AREAS_OF_CONCERN_IN_SKIN_EXCESSIVE_OIL_SHINE", value);
    },

    updateAreasOfConcernInSkinWrinklesFineLines({ commit }, value: boolean) {
      commit("SET_AREAS_OF_CONCERN_IN_SKIN_WRINKLES_FINE_LINES", value);
    },

    updateAreasOfConcernInSkinDullDrySkin({ commit }, value: boolean) {
      commit("SET_AREAS_OF_CONCERN_IN_SKIN_DULL_DRY_SKIN", value);
    },

    updateAreasOfConcernInSkinRosacea({ commit }, value: boolean) {
      commit("SET_AREAS_OF_CONCERN_IN_SKIN_ROSACEA", value);
    },

    updateAreasOfConcernInSkinBrokenCapillaries({ commit }, value: boolean) {
      commit("SET_AREAS_OF_CONCERN_IN_SKIN_BROKEN_CAPILLARIES", value);
    },

    updateAreasOfConcernInSkinRednessRuddiness({ commit }, value: boolean) {
      commit("SET_AREAS_OF_CONCERN_IN_SKIN_REDNESS_RUDDINESS", value);
    },

    updateAreasOfConcernInSkinDehydration({ commit }, value: boolean) {
      commit("SET_AREAS_OF_CONCERN_IN_SKIN_DEHYDRATION", value);
    },

    updateAreasOfConcernInSkinSpots({ commit }, value: boolean) {
      commit("SET_AREAS_OF_CONCERN_IN_SKIN_SPOTS", value);
    },

    updateAreasOfConcernInSkinOther({ commit }, value: string) {
      commit("SET_AREAS_OF_CONCERN_IN_SKIN_OTHER", value);
    },
    /// areas_of_concern_in_skin

    /// skin_type_description
    updateSkinTypeDescription({ commit }, value: string) {
      commit("SET_SKIN_TYPE_DESCRIPTION", value);
    },
    /// skin_type_description

    /// skin_type
    updateSkinType({ commit }, value: string) {
      commit("SET_SKIN_TYPE", value);
    },
    /// skin_type

    /// have_you_tanning_last_48_hours
    updateHaveYouTanningLast48Hours({ commit }, value: boolean) {
      commit("SET_HAVE_YOU_TANNING_LAST_48_HOURS", value);
    },
    /// have_you_tanning_last_48_hours

    /// are_you_taking_medications_and_othersc
    updateAreYouTakingMedicationsAndOthers({ commit }, value: boolean) {
      commit("SET_ARE_YOU_TAKING_MEDICATIONS_AND_OTHERS", value);
    },
    updateAreYouTakingMedicationsAndOthersOral({ commit }, value: string) {
      commit("SET_ARE_YOU_TAKING_MEDICATIONS_AND_OTHERS_ORAL", value);
    },
    updateAreYouTakingMedicationsAndOthersTopical({ commit }, value: string) {
      commit("SET_ARE_YOU_TAKING_MEDICATIONS_AND_OTHERS_TOPICAL", value);
    },
    /// are_you_taking_medications_and_othersc

    /// currently_use_products_with_glycolic_acid_and_others
    updateCurrentlyUseProductsWithGlycolicAcidAndOthers(
      { commit },
      value: boolean
    ) {
      commit("SET_CURRENTLY_USE_PRODUCTS_WITH_GLYCOLIC_ACID_AND_OTHERS", value);
    },
    updateCurrentlyUseProductsWithGlycolicAcidAndOthersWhichOnes(
      { commit },
      value: string
    ) {
      commit(
        "SET_CURRENTLY_USE_PRODUCTS_WITH_GLYCOLIC_ACID_AND_OTHERS_WHICH_ONES",
        value
      );
    },
    /// currently_use_products_with_glycolic_acid_and_others

    /// currently_use_accutane_and_others
    updateCurrentlyUseAccutaneAndOthers({ commit }, value: boolean) {
      commit("SET_CURRENTLY_USE_ACCUTANE_AND_OTHERS", value);
    },
    updateCurrentlyUseAccutaneAndOthersWhichOnes({ commit }, value: string) {
      commit("SET_CURRENTLY_USE_ACCUTANE_AND_OTHERS_WHICH_ONES", value);
    },
    updateCurrentlyUseAccutaneAndOthersHowLong({ commit }, value: string) {
      commit("SET_CURRENTLY_USE_ACCUTANE_AND_OTHERS_HOW_LONG", value);
    },
    /// currently_use_accutane_and_others

    /// have_received_botox_and_others
    updateHaveReceivedBotoxAndOthers({ commit }, value: boolean) {
      commit("SET_HAVE_RECEIVED_BOTOX_AND_OTHERS", value);
    },
    updateHaveReceivedBotoxAndOthersDetails({ commit }, value: string) {
      commit("SET_HAVE_RECEIVED_BOTOX_AND_OTHERS_DETAILS", value);
    },
    /// have_received_botox_and_others

    /// had_facial_before
    updateHadFacialBefore({ commit }, value: boolean) {
      commit("SET_HAD_FACIAL_BEFORE", value);
    },
    updateHadFacialBeforeWhen({ commit }, value: string) {
      commit("SET_HAD_FACIAL_BEFORE_WHEN", value);
    },
    updateHadFacialBeforeHowOften({ commit }, value: string) {
      commit("SET_HAD_FACIAL_BEFORE_HOW_OFTEN", value);
    },
    /// had_facial_before
    resetFormState({ commit }) {
      // Acción para llamar a la mutación de reinicio
      commit("resetFormState");
    },
  },
  getters: {
    form: (state) => state.form,
  },
  plugins: [
    createPersistedState({
      paths: ["form"],
    }),
  ],
});
