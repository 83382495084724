<template>
  <div :class="[skinType == null ? 'bg-red-500' : '']" class="p-2">
    <TwLabel>{{ $t("what_type_skin_do_you_have") }}</TwLabel>
    <TwRadioButton
      name="skin_type"
      v-model="skinType"
      :options="[
        { label: $t('normal'), value: 'normal' },
        { label: $t('oily'), value: 'oily' },
        { label: $t('dry'), value: 'dry' },
        { label: $t('combination'), value: 'combination' },
      ]"
    />
  </div>
</template>

<script setup lang="ts">
import { WritableComputedRef, computed } from "vue";
import TwLabel from "../TwLabel.vue";
import TwRadioButton from "../TwRadioButton.vue";
import { useStore } from "vuex";

const store = useStore();

const skinType: WritableComputedRef<string> = computed({
  get: () => store.state.form.skin_type,
  set: (value) => store.dispatch("updateSkinType", value),
});
</script>
