<template>
  <div>
    <div
      v-for="option in options"
      :key="option.value"
      class="flex items-center mb-2"
    >
      <label class="flex items-center cursor-pointer">
        <input
          type="radio"
          :name="name"
          :value="option.value"
          v-model="localValue"
          class="form-radio"
          @change="updateValue"
        />
        <span class="ml-2">{{ option.label }}</span>
      </label>
    </div>
  </div>
</template>

<script setup lang="ts">
import { defineProps, defineEmits, ref, watch } from "vue";

const props = defineProps({
  options: {
    type: Array as () => Array<{ label: string; value: string }>,
    required: true,
  },
  modelValue: {
    type: String,
    required: true,
  },
  name: {
    type: String,
    required: true,
  },
});

const emits = defineEmits(["update:modelValue"]);
const localValue = ref(props.modelValue);

watch(
  () => props.modelValue,
  (newValue) => {
    localValue.value = newValue;
  }
);

const updateValue = () => {
  emits("update:modelValue", localValue.value);
};
</script>
