<template>
  <div :class="[skinTypeDescription == null ? 'bg-red-500' : '']" class="p-2">
    <TwLabel>{{ $t("describe_your_skin_type") }}</TwLabel>
    <TwRadioButton
      name="skin_type_description"
      v-model="skinTypeDescription"
      :options="[
        { label: $t('skin_type_creamy'), value: 'skin_type_creamy' },
        { label: $t('skin_type_light'), value: 'skin_type_light' },
        { label: $t('skin_type_light_matte'), value: 'skin_type_light_matte' },
        { label: $t('skin_type_matte'), value: 'skin_type_matte' },
        { label: $t('skin_type_brown'), value: 'skin_type_brown' },
        { label: $t('skin_type_black'), value: 'skin_type_black' },
      ]"
    />
  </div>
</template>

<script setup lang="ts">
import { WritableComputedRef, computed } from "vue";
import TwLabel from "../TwLabel.vue";
import TwRadioButton from "../TwRadioButton.vue";
import { useStore } from "vuex";

const store = useStore();

const skinTypeDescription: WritableComputedRef<string> = computed({
  get: () => store.state.form.skin_type_description,
  set: (value) => store.dispatch("updateSkinTypeDescription", value),
});
</script>
