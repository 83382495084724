<template>
  <div
    :class="[haveYouTanningLast48Hours == null ? 'bg-red-500' : '']"
    class="p-2"
  >
    <TwLabel>{{ $t("have_you_tanning_last_48_hours") }}</TwLabel>
    <TwButtonsYesAndNo
      :isActive="haveYouTanningLast48Hours"
      @onClickYes="updateHaveYouTanningLast48Hours(true)"
      @onClickNo="updateHaveYouTanningLast48Hours(false)"
    />
  </div>
</template>

<script setup lang="ts">
import { ComputedRef, computed } from "vue";
import { useStore } from "vuex";
import TwLabel from "../TwLabel.vue";
import TwButtonsYesAndNo from "../TwButtonsYesAndNo.vue";

const store = useStore();

const haveYouTanningLast48Hours: ComputedRef<boolean> = computed(
  () => store.state.form.have_you_tanning_last_48_hours
);
const updateHaveYouTanningLast48Hours = (value: boolean) => {
  store.dispatch("updateHaveYouTanningLast48Hours", value);
};
</script>

<style scoped></style>
