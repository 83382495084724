<template>
  <div
    :class="[experienceSkinBreakouts == null ? 'bg-red-500' : '']"
    class="p-2"
  >
    <TwLabel>{{ $t("experience_skin_breakouts") }}</TwLabel>
    <TwButtonsYesAndNo
      :isActive="experienceSkinBreakouts"
      @onClickYes="updateExperienceSkinBreakouts(true)"
      @onClickNo="updateExperienceSkinBreakouts(false)"
    />
  </div>
</template>

<script setup lang="ts">
import { ComputedRef, computed } from "vue";
import { useStore } from "vuex";
import TwLabel from "../TwLabel.vue";
import TwButtonsYesAndNo from "../TwButtonsYesAndNo.vue";

const store = useStore();

const experienceSkinBreakouts: ComputedRef<boolean> = computed(
  () => store.state.form.experience_skin_breakouts
);
const updateExperienceSkinBreakouts = (value: boolean) => {
  store.dispatch("updateExperienceSkinBreakouts", value);
};
</script>

<style scoped></style>
