<template>
  <div :class="[experienceOilyShine == null ? 'bg-red-500' : '']" class="p-2">
    <TwLabel>{{ $t("experience_oily_shine") }}</TwLabel>
    <TwButtonsYesAndNo
      :isActive="experienceOilyShine"
      @onClickYes="updateExperienceOilyShine(true)"
      @onClickNo="updateExperienceOilyShine(false)"
    />
  </div>
</template>

<script setup lang="ts">
import { ComputedRef, computed } from "vue";
import { useStore } from "vuex";
import TwLabel from "../TwLabel.vue";
import TwButtonsYesAndNo from "../TwButtonsYesAndNo.vue";

const store = useStore();

const experienceOilyShine: ComputedRef<boolean> = computed(
  () => store.state.form.experience_oily_shine
);
const updateExperienceOilyShine = (value: boolean) => {
  store.dispatch("updateExperienceOilyShine", value);
};
</script>

<style scoped></style>
