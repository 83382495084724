<template>
  <div>
    <div
      v-for="option in options"
      :key="option.value"
      class="flex items-center mt-2 mb-2"
    >
      <label class="flex items-center cursor-pointer">
        <input
          type="checkbox"
          :value="option.value"
          v-model="localValues"
          :id="option.value"
          class="form-checkbox"
          @change="updateValue"
        />
        <span class="ml-2">{{ option.label }}</span>
      </label>
    </div>
  </div>
</template>

<script setup lang="ts">
import { defineProps, defineEmits, ref, watch } from "vue";

const props = defineProps({
  options: {
    type: Array as () => Array<{ label: string; value: string }>,
    required: true,
  },
  modelValue: {
    type: Array as () => string[],
    required: true,
  },
});

const emits = defineEmits(["update:modelValue"]);
const localValues = ref([...props.modelValue]);

watch(
  () => props.modelValue,
  (newValue) => {
    localValues.value = [...newValue];
  },
  { immediate: true }
);

const updateValue = () => {
  emits("update:modelValue", localValues.value);
};
</script>
