<template>
  <textarea
    :id="id"
    v-model="localValue"
    class="mt-2 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
    :rows="rows"
    @input="updateValue"
  ></textarea>
</template>

<script setup lang="ts">
import { defineProps, defineEmits, ref, watch } from "vue";

const props = defineProps({
  id: {
    type: String,
    required: false,
  },
  modelValue: {
    type: String,
    required: true,
  },
  rows: {
    type: Number,
    default: 4,
  },
});

const emits = defineEmits(["update:modelValue"]);
const localValue = ref(props.modelValue);

watch(
  () => props.modelValue,
  (newValue) => {
    localValue.value = newValue;
  }
);

const updateValue = (event: Event) => {
  const target = event.target as HTMLTextAreaElement;
  emits("update:modelValue", target.value);
};
</script>
