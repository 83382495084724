<!-- HadFacial.vue -->
<template>
  <div
    :class="[currentlyUseAccutaneAndOthers == null ? 'bg-red-500' : '']"
    class="p-2"
  >
    <TwLabel>{{ $t("currently_use_accutane_and_others") }}</TwLabel>
    <TwButtonsYesAndNo
      :isActive="currentlyUseAccutaneAndOthers"
      @onClickYes="updateCurrentlyUseAccutaneAndOthers(true)"
      @onClickNo="updateCurrentlyUseAccutaneAndOthers(false)"
    />
    <div v-if="currentlyUseAccutaneAndOthers" class="ml-4 mr-4">
      <TwLabel>{{ $t("which_ones") }}</TwLabel>
      <TwTextarea
        v-model="currentlyUseAccutaneAndOthersWhichOnes"
        :rows="2"
        id="currentlyUseAccutaneAndOthersWhichOnes"
      />
      <TwLabel>{{ $t("how_long") }}</TwLabel>
      <TwTextarea
        v-model="currentlyUseAccutaneAndOthersHowLong"
        :rows="2"
        id="currentlyUseAccutaneAndOthersHowLong"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ComputedRef, computed, WritableComputedRef } from "vue";
import { useStore } from "vuex";
import TwLabel from "../TwLabel.vue";
import TwTextarea from "../TwTextarea.vue";
import TwButtonsYesAndNo from "../TwButtonsYesAndNo.vue";

const store = useStore();

const currentlyUseAccutaneAndOthers: ComputedRef<boolean> = computed(
  () => store.state.form.currently_use_accutane_and_others
);
const currentlyUseAccutaneAndOthersWhichOnes: WritableComputedRef<string> =
  computed({
    get: () => store.state.form.currently_use_accutane_and_others_which_ones,
    set: (value) =>
      store.dispatch("updateCurrentlyUseAccutaneAndOthersWhichOnes", value),
  });
const currentlyUseAccutaneAndOthersHowLong: WritableComputedRef<string> =
  computed({
    get: () => store.state.form.currently_use_accutane_and_others_how_long,
    set: (value) =>
      store.dispatch("updateCurrentlyUseAccutaneAndOthersHowLong", value),
  });

const updateCurrentlyUseAccutaneAndOthers = (value: boolean) => {
  store.dispatch("updateCurrentlyUseAccutaneAndOthers", value);
};
</script>

<style scoped></style>
